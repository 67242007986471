import React, { useEffect, useState } from "react";
const firebase = require("firebase");
const moment = require("moment");
// Required for side-effects
require("firebase/firestore");
const firebaseConfig = {
  apiKey: "AIzaSyCXZunFf1vsOeAdlZt6bFX122sxh_DkJDk",
  authDomain: "commute-1570756441148.firebaseapp.com",
  databaseURL: "https://commute-1570756441148.firebaseio.com",
  projectId: "commute-1570756441148",
  storageBucket: "commute-1570756441148.appspot.com",
  messagingSenderId: "902991447581",
  appId: "1:902991447581:web:688fe7b0dfd1af7e20e8f4",
  measurementId: "G-VZ1B5RZKV1"
};
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const data = [];
    db.collection("origins")
      .get()
      .then(qs => {
        Promise.all(
          qs.docs.map(doc => {
            const origin = doc.id;
            const destinations = [];
            return db
              .collection(`origins/${origin}/destinations`)
              .get()
              .then(qs => {
                return Promise.all(
                  qs.docs.map(doc => {
                    const destination = doc.id;
                    const times = [];
                    return db
                      .collection(
                        `origins/${origin}/destinations/${destination}/times`
                      )
                      .get()
                      .then(qs => {
                        qs.forEach(doc => {
                          times.push(doc.data());
                        });
                        destinations.push({
                          destination,
                          times
                        });
                      });
                  })
                );
              })
              .then(() => {
                data.push({
                  origin,
                  destinations
                });
              });
            // const { result, timestamp, utcOffset } = doc.data();
            // const { origin_addresses, destination_addresses, rows } = result;
            // const entries = [];

            // origin_addresses.forEach((origin, i) => {
            //   destination_addresses.forEach((dest, j) => {
            //     entries.push({
            //       origin,
            //       dest,
            //       result: rows[i].elements[j]
            //     });
            //   });
            // });

            // data.push({
            //   entries,
            //   moment: moment.unix(timestamp).utcOffset(utcOffset)
            // });
          })
        ).then(() => {
          console.log(data);
          setData(data);
          setIsLoading(false);
        });
      });
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div className="App">
      <h1>Times</h1>
      {data.map(d => (
        <div>
          <h1 key={d.origin}>From {d.origin}</h1>
          <div>
            {d.destinations.map(dest => (
              <>
                <h2>To {dest.destination}</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Leaving at</th>
                      <th>Time</th>
                      <th>Distance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dest.times.map(t => (
                      <tr>
                        <td>
                          {moment
                            .unix(t.timestamp)
                            .utcOffset(t.utcOffset)
                            .format("ddd MMM D, h:mm A")}
                        </td>
                        <td>
                          {Math.floor(t.duration_in_traffic.value / 60)} minutes
                        </td>
                        <td>{t.distance.text}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default App;
